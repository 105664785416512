import React, { useState, useEffect } from "react";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [display, setDisplay] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Hardcoded username and password
    const hardcodedUsername = "wineclub";
    const hardcodedPassword = "emhwp2023";

    if (username === hardcodedUsername && password === hardcodedPassword) {
      alert("Login Successful");
      localStorage.setItem("loggedIn", "true");
      setDisplay(false);
    } else {
      alert("Incorrect Username or Password");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("loggedIn") === "true") {
      setDisplay(false);
    }
  }, []);

  if (!display) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
        background: "white",
      }}>
      <form
        onSubmit={handleSubmit}
        style={{
          backgroundColor: "#fff",
          padding: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
          flexDirection: "column",
        }}>
        <div>
          <label>
            Username:
            <input
              type="text"
              value={username}
              style={{ marginLeft: 10 }}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Password:
            <input
              type="password"
              value={password}
              style={{ marginLeft: 10 }}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </div>
        <div>
          <button
            style={{
              outline: "none",
              background: "#EEEFFF",
              border: "none",
              padding: "10px 20px",
              boxShadow: "0px 0px 1px rgba(0,0,0,0.2)",
              cursor: "pointer",
            }}
            type="submit">
            Login
          </button>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
