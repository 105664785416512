import React, { useState, useEffect } from "react";
import "../Assets/Css/VerifyAge.css";
import localforage from "localforage";
import logoImg from "../Assets/Css/imgs/eleven-madison-home-logo.png";

export default function AgeVerify({}) {
  const [showAgeVerify, setShowAgeVerify] = useState(false);

  useEffect(() => {
    getAgeVerify();
  }, []);

  async function getAgeVerify() {
    const data = await localforage.getItem("age_verification");
    console.log(data);
    if (data !== "verified") {
      setShowAgeVerify(true);
    }
  }

  function confirmAge() {
    localforage.setItem("age_verification", "verified");
    setShowAgeVerify(false);
  }
  return (
    <>
      <div
        className="bg-verify-overlay"
        style={{ display: showAgeVerify ? "block" : "none" }}></div>

      <div
        className="age-verify-popup "
        style={{ display: showAgeVerify ? "block" : "none" }}>
        <div className="age-verify-logo">
          <div
            className="age-verify-logo-image"
            style={{ marginTop: 20, marginBottom: 40 }}>
            <img className="verify-logo" src={logoImg} alt="logo" />
          </div>
        </div>
        <div className="m-a-v__header">
          <div
            className="age-verify-sub-heading"
            style={{ fontFamily: "PTSerifBold" }}>
            By entering this page, you verify you are 21 or older.
          </div>
        </div>
        <div className="m-a-v__actions">
          <div className="m-a-v__action m-a-v__enter" style={{ marginTop: 30 }}>
            <button
              className="section-2-bottom-header-button first-purchase-btn"
              type="button"
              onClick={confirmAge}>
              Enter Eleven Madison Wine Club
            </button>
          </div>
          <div className="m-a-v__action m-a-v__exit">
            <a
              className="m-a-v__link"
              href="https://google.com"
              style={{ marginTop: "10px", textDecoration: "none" }}>
              Exit
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
