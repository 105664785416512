import React from "react";
import logoImg from "../../Assets/Css/imgs/eleven-madison-home-logo.png";

const Header = ({ setOpenCart, openCart, cart }) => {
  const count = cart?.reduce((acc, item) => acc + item.quantity, 0);
  return (
    <div className="header-container">
      <div></div>
      <div className="emw-home-logo">
        <img src={logoImg} alt="Logo" />
      </div>

      <div
        className="cart-button"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setOpenCart(!openCart);
        }}>
        Cart - {count || 0}
      </div>
    </div>
  );
};

export default Header;
