import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
// import Bottle from '../Icons/Bottle';

const SingleProduct = ({
  openCart,
  setOpenCart,
  addItemToCart,
  title,
  smallText,
  subTitle,
  description,
  id,
  featuredImg,
  price,
  productDescription,
  product,
}) => {
  const newProduct = {
    name: title,
    id: id,
    quantity: 1,
    img: featuredImg, // use an img link here
    price,
    productDescription,
    ...product,
  };

  function addClick(oneTime, type = "recurring") {
    setOpenCart(!openCart);
    addItemToCart(
      {
        ...newProduct,
        purchaseType: oneTime ? `once` : "subscribe",
      },
      1,
      oneTime,
      type
    );
  }

  return (
    <div className="collection-container">
      <div className="section2-left-picture-container">
        <img
          alt=""
          loading="lazy"
          className="section2-left-container-left"
          effect="blur"
          src={featuredImg?.url}
        />
      </div>
      <div className="section2-bottom-header">
        <h2 className="segoe-font-title wine-collection-title">{title}</h2>
        <p className="segeo-font-title wine-title" style={{ fontWeight: 300 }}>
          {smallText}
        </p>

        <div className="section-2-paragraph-font section2-bottom-paragraph">
          {description}
        </div>
        <div className="section-2-bottom-header-button-wrapper">
          <div className="product-button-wrapper">
            {product?.shopifyVariantId ? (
              <button
                className="section-2-bottom-header-button first-purchase-btn"
                onClick={() => addClick(false, "recurring")}>
                Start Subscription
              </button>
            ) : null}

            {product?.shopifyVariantIdOnetime ? (
              <button
                className="section-2-bottom-header-button second-purchase-btn"
                onClick={() => addClick(true, "1")}>
                Buy One Month
              </button>
            ) : null}
            {product?.shopifyVariantIdThreeGift ? (
              <button
                className="section-2-bottom-header-button second-purchase-btn"
                onClick={() => addClick(true, "3")}>
                Buy Three Months
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
