import React from 'react';

const Card = ({ imageUrl, title, description, readMoreLink, alt }) => {
  return (
    <div className='storyCard-container'>
      <div className='storyCard-content'>
        <img className='storyCard-image' alt={alt || 'Wine Glasses and Plants'} loading='lazy' effect='blur' src={imageUrl} />
        <div className='storyCard-text'>
          <h3 className='storyCard-title'>{title}</h3>
          <p className='storyCard-description'>{description}</p>
          <a href={readMoreLink} className='storyCard-link'>
            Read more
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
