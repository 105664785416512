import React from "react";
import ReactDOM from "react-dom";
import "./Assets/Css/index.css";
import "./Assets/Css/App.css";
// import "./Fonts/Nominee/Nominee-Bold.woff";
// import "./Fonts/Nominee/Nominee-Condensed.woff";
// import "./Fonts/Nominee/Nominee-Light.woff";
// import "./Fonts/Nominee/Nominee-Medium.woff";
// import "./Fonts/Nominee/Nominee-MediumCondensed.woff";
// import "./Fonts/Nominee/Nominee-MediumExtended.woff";

// import "./Fonts/Nominee/Nominee-Bold.otf";
// import "./Fonts/Nominee/Nominee-Condensed.otf";
// import "./Fonts/Nominee/Nominee-Light.otf";
// import "./Fonts/Nominee/Nominee-Medium.otf";
// import "./Fonts/Nominee/Nominee-MediumCondensed.otf";
// import "./Fonts/Nominee/Nominee-MediumExtended.otf";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
