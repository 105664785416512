import { queryParcelleStrapiDB, queryShopify } from "./config";

export async function getProducts() {
  const strapiAnswer = await queryParcelleStrapiDB("products")
    .then((result) => {
      return result?.data?.data?.map((el) => {
        const item = el?.attributes;
        const image = item?.featured_image?.data?.attributes;
        return {
          price: item?.price,
          id: el?.id,
          _createdAt: item?.createdAt,
          _status: "published",
          shopifyVariantId: item?.shopify_variant_id,
          oneTimeVariantId: item?.onetime_variant_id,
          shopifyVariantIdOnetime: item?.shopify_variant_id_onetime,
          shopifyVariantIdThreeGift: item?.shopify_variant_id_3_gift,
          productTitle: item?.product_title,
          productSku: item?.sku,
          active: true,
          subtitle: item?.subtitle,
          featuredImage: {
            url: `http://cms.parcellewine.com${image?.url}`,
            alt: image?.alternativeText,
            width: image?.width,
            height: image?.width,
            format: image?.mime,
            blurUpThumb: `http://cms.parcellewine.com${image?.formats?.small?.url}`,
            blurhash: image?.hash,
            filename: image?.name,
          },
          productDescription: item?.description,
          secondTitle: item?.second_title,
          productId: item?.product_id,
          sortOrder: item?.sort_order,
        };
      });
    })
    .catch(() => {
      return false;
    });

  return strapiAnswer;
}

export async function getHeroData() {
  const strapiAnswer = await queryParcelleStrapiDB("hero")
    .then((result) => {
      const item = result?.data?.data?.attributes;
      const image = item?.hero_image?.data?.attributes;
      return {
        aboutTitle: item?.about_title,
        buttonText: item?.button_text,
        link: item?.link,
        paragraph: item?.paragraph,
        heroImage: {
          blurUpThumb: `http://cms.parcellewine.com${image?.formats?.small?.url}`,
          url: `http://cms.parcellewine.com${image?.url}`,
          alt: image?.alternativeText,
          responsiveImage: {
            src: `http://cms.parcellewine.com${image?.url}`,
          },
        },
        mainTitle: item?.main_title,
        mainTitleLb: item?.main_title_linebreak,
      };
    })
    .catch(() => {
      return false;
    });
  return strapiAnswer;
}

export async function getServerProducts() {
  const answer = await queryShopify("w_l_parcelle/getEMWCProducts")
    .then((result) => {
      return result?.data?.data?.products;
    })
    .catch(() => {
      return false;
    });
  return answer;
}

export async function getAboutData() {
  const strapiAnswer = await queryParcelleStrapiDB("about")
    .then((result) => {
      const item = result?.data?.data?.attributes;
      const image1 = item?.image_1?.data?.attributes;
      const image2 = item?.image_2?.data?.attributes;
      const image3 = item?.image_3?.data?.attributes;
      return {
        id: result?.data?.data?.id,
        header: item?.Header,
        paragraph: item?.Paragraph,
        link: item?.link,
        image1: {
          url: `http://cms.parcellewine.com${image1?.url}`,
          alt: image1?.alternativeText,
          width: image1?.width,
          height: image1?.width,
          format: image1?.mime,
          blurUpThumb: `http://cms.parcellewine.com${image1?.formats?.small?.url}`,
          blurhash: image1?.hash,
          filename: image1?.name,
        },
        image2: {
          url: `http://cms.parcellewine.com${image2?.url}`,
          alt: image2?.alternativeText,
          width: image2?.width,
          height: image2?.width,
          format: image2?.mime,
          blurUpThumb: `http://cms.parcellewine.com${image2?.formats?.small?.url}`,
          blurhash: image2?.hash,
          filename: image2?.name,
        },
        image3: {
          url: `http://cms.parcellewine.com${image3?.url}`,
          alt: image3?.alternativeText,
          width: image3?.width,
          height: image3?.width,
          format: image3?.mime,
          blurUpThumb: `http://cms.parcellewine.com${image3?.formats?.small?.url}`,
          blurhash: image3?.hash,
          filename: image3?.name,
        },
        image1Title: item?.image_1_title,
        image1Paragraph: item?.image_1_paragraph,
        image1Link: item?.image_1_link,
        image2Title: item?.image_2_title,
        image2Paragraph: item?.image_2_paragraph,
        image2Link: item?.image_2_link,
        image3Title: item?.image_3_title,
        image3Paragraph: item?.image_3_paragraph,
        image3Link: item?.image_3_link,
      };
    })
    .catch(() => {
      return false;
    });
  return strapiAnswer;
}

export async function getContentData() {
  const strapiAnswer = await queryParcelleStrapiDB("content")
    .then((result) => {
      const item = result?.data?.data?.attributes;
      const image = item?.Images?.data?.[0]?.attributes;

      return {
        header: item?.Header,
        paragraph: item?.Paragraph,
        images: [
          {
            url: `http://cms.parcellewine.com${image?.url}`,
            alt: image?.alternativeText,
            width: image?.width,
            height: image?.width,
            format: image?.mime,
            blurUpThumb: `http://cms.parcellewine.com${image?.formats?.small?.url}`,
            blurhash: image?.hash,
            filename: image?.name,
          },
        ],
      };
    })
    .catch(() => {
      return false;
    });

  return strapiAnswer;
}
