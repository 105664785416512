import React from 'react';

const Hero = ({ heroData }) => {
  function NewlineText(paragraph) {
    const text = paragraph;
    const newText = text.split('\n').map((str) => (
      <>
        {str}
        <br />
      </>
    ));

    return newText;
  }

  return (
    <div className='container'>
      <div className='hero-container '>
        <div className='hero-text-container'>
          <h1
            className='noe-font-title hero-h1'
            style={{
              fontFamily: 'Nominee',
              letterSpacing: 4,
              color: '#846032',
            }}
          >
            {heroData?.aboutTitle ? heroData?.aboutTitle : 'Wine Club'}
          </h1>
          <h3 className='hero-h3'>
            {heroData?.paragraph ? (
              NewlineText(heroData?.paragraph)
            ) : (
              <p>Each month, you will receive our favorite wines to drink at that moment. Purchase a one or three-month package, or commit to an ongoing relationship to have our favorite wines delivered to your front door each month. Subscribers can cancel or upgrade at any time.</p>
            )}
            <>
              <br />
              <span className='contact_span'>
                Any questions? Contact us at <a href='mailto:thewineclub@parcellewine.com'> thewineclub@parcellewine.com </a> <br />
              </span>
            </>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Hero;
