import React, { useState, useEffect } from "react";
import Header from "./Components/UI/Header";
import Hero from "./Components/UI/Hero";
import SingleProduct from "./Components/Product/SingleProduct";
import Slideout from "./Components/Cart/Slideout";
import {
  getServerProducts,
  getHeroData,
  getProducts,
  getAboutData,
  getContentData,
} from "./Api/products.api";
import { Helmet, HelmetProvider } from "react-helmet-async";
import localforage from "localforage";
import "react-lazy-load-image-component/src/effects/blur.css";
import Client from "shopify-buy";
import AgeVerify from "./Components/AgeVerify";
import About from "./Components/UI/About";
import Banner from "./Components/Banner/Banner";
import Content from "./Components/UI/Content";
import LoginForm from "./Components/AuthPop";

const client = Client.buildClient({
  domain: "parcellewhitelabel.myshopify.com",
  storefrontAccessToken: "e8a92037f58f07de10afaab38014a7d4",
});

export default function App() {
  const [openCart, setOpenCart] = useState(false);
  const [cart, setCart] = useState([]);
  const [products, setProducts] = useState([]);
  const [heroData, setHeroData] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const overlayOpen = openCart ? "hiddendiv overlay" : "hiddendiv";

  const showDialog = () => {
    const scrollY =
      document.documentElement.style.getPropertyValue("--scroll-y");
    const body = document.body;
    body.style.position = "fixed";
    body.style.top = `-${scrollY}`;
  };

  const closeDialog = () => {
    const body = document.body;
    const scrollY =
      document.documentElement.style.getPropertyValue("--scroll-y");
    body.style.position = "";
    body.style.top = `-${scrollY}`;
  };

  const addItemToCart = (addedItem, quantity, oneTimeCharge, chargeType) => {
    if (chargeType === "recurring") {
      addedItem = {
        ...addedItem,
        id: addedItem.shopifyVariantId,
        secondTitle: "Subscription",
      };
    } else if (chargeType === "1") {
      addedItem = {
        ...addedItem,
        id: addedItem.shopifyVariantIdOnetime,
        secondTitle: "One Time Purchase",
      };
    } else if (chargeType === "3") {
      addedItem = {
        ...addedItem,
        id: addedItem.shopifyVariantIdThreeGift,
        secondTitle: "3 Prepaid Shipments",
        price: addedItem.price * 3,
      };
    }

    console.log("ADDING ITEM", addedItem, "EXOSTOMG", cart);
    let UpdatedProduct = cart.map((item) =>
      item.id === addedItem.id && addedItem.secondTitle === item.secondTitle
        ? {
            ...item,
            quantity: parseInt(item.quantity) + parseInt(addedItem.quantity),
          }
        : item
    );
    let isPresent = cart.some(function (el) {
      return el.id === addedItem.id;
    });
    const updatedCart = isPresent ? UpdatedProduct : [...cart, addedItem];
    setCart(updatedCart);
    localforage.setItem("cart", updatedCart);
  };

  // INIT FUNCTION
  useEffect(() => {
    loadProducts();
    loadHeroData();
    loadAboutData();
  }, []);

  // load about data

  async function loadAboutData() {
    const aboutDataFromApi = await getAboutData();
    console.log("aboutDataFromApi", aboutDataFromApi);
    setAboutData(aboutDataFromApi);
    const contentDataFromApi = await getContentData();
    console.log("contentDataFromApi", contentDataFromApi);
    setContentData(contentDataFromApi);
  }

  async function loadHeroData() {
    const newHeroData = await getHeroData();
    setHeroData(newHeroData);
  }

  async function handleCheckout() {
    setLoading(true);
    const lineItemsToAdd = cart.map((cartItem) => ({
      ...cartItem,
      variantId: parseInt(cartItem.shopifyVariantId),
      quantity: cartItem.quantity,
    }));

    let myUrl = "https://fulfillment.parcellewine.com/cart/";
    lineItemsToAdd.forEach((lili, index) => {
      myUrl += index === 0 ? "add?" : "&";
      myUrl += `id[]=${lili?.id}&quantity=${lili.quantity}`;

      // ONE TIME PURCHASE
      if (
        lili?.id === "44438178234610" ||
        lili?.id === "44438168699122" ||
        lili?.id === "44438171582706"
      ) {
      }
      // SUBSCRIPTION PURCHASE
      else if (
        lili?.id === "44438171549938" ||
        lili?.id === "44438168666354" ||
        lili?.id === "44438178201842"
      ) {
        myUrl += `&selling_plan=${4698734834}`;
      }
      // 3 MONTH GIFT PURCHASE
      else if (
        lili?.id === "44438171615474" ||
        lili?.id === "44438168731890" ||
        lili?.id === "44438178267378"
      ) {
        myUrl += `&properties%5Bprepaid_gift%5D=prepaid&properties%5Bprepaid_shipments%5D=3`;
      }
    });
    console.log("myUrl", myUrl);
    window.location = myUrl;
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }
  // https://fulfillment.parcellewine.com/cart/add?id=37747551928507&prepaid_gift=prepaid&prepaid_shipments=3&quantity=1
  // https://fulfillment.parcellewine.com/cart/add?id=37747551928507&properties%5Bprepaid_gift%5D=prepaid&properties%5Bprepaid_shipments%5D=3&quantity=1
  async function loadProducts() {
    await getServerProducts();
    let cmsProducts = await getProducts();

    let mergedProducts = [];
    console.log("cmsProducts", cmsProducts);
    client.product.fetchAll(30).then((shopProds) => {
      // console.log(shopProds);
      const shopifyProducts = shopProds.filter(
        (el) => true //wHHProductList.includes(el.id)
      );
      cmsProducts?.forEach((el) => {
        const shopProd = shopifyProducts.filter(
          (item) => item.id === el.shopifygraphqlid
        );
        let myShopPrd = {
          id: shopProd[0]?.id,
          variants: shopProd[0]?.variants.map((el) => ({
            price: el?.price,
            id: el?.id,
            title: el?.title,
            sku: el?.sku,
            available: el?.available,
          })),
          variantId: shopProd[0]?.variants[0].id,
          availableForSale: shopProd[0]?.availableForSale,
        };
        mergedProducts.push({
          ...el,
          ...myShopPrd,
        });
      });

      console.log(mergedProducts, "mergedProducts");
      setProducts(mergedProducts);
    });
    localforage.getItem("cart", (_, value) => {
      if (value) setCart(value);
    });
  }

  return (
    <HelmetProvider>
      <div className="App  ">
        <Helmet>
          <title>Eleven Madison Home | Parcelle Wine</title>
        </Helmet>
        {/* <LoginForm /> */}
        <div
          className={overlayOpen}
          onClick={() => {
            setOpenCart(!openCart);
            openCart ? closeDialog() : showDialog();
          }}></div>
        <AgeVerify />
        <Slideout
          {...{
            openCart,
            setOpenCart,
            showDialog,
            closeDialog,
            cart,
            setCart,
            handleCheckout,
            loading,
          }}
        />
        <Header
          {...{ openCart, setOpenCart, showDialog, closeDialog, cart: cart }}
          showBadge={cart.length}
        />
        <Banner
          {...{
            heroData,
          }}
        />
        <Hero heroData={heroData} />
        <div className=" product-section-wrapper container">
          <div className="product-grid-wrapper">
            {products &&
              products
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((product) => {
                  if (!product?.featuredImage || !product?.price) return null;
                  return (
                    <SingleProduct
                      key={product.id}
                      product={product}
                      {...{
                        openCart,
                        setOpenCart,
                        showDialog,
                        closeDialog,
                        addItemToCart,
                        cart,
                      }}
                      id={product.id}
                      title={product.productTitle}
                      smallText={product.subtitle}
                      subTitle={product.secondTitle}
                      description={product.productDescription}
                      featuredImg={product.featuredImage}
                      imgs={product.productImages}
                      price={product.price}
                      productDescription={product.productDescription}
                    />
                  );
                })}
          </div>
        </div>
        <div className="container">
          <Content contentData={contentData} />
        </div>
        <div style={{ marginBottom: 50, maxWidth: "90%", margin: "auto" }}>
          <About aboutData={aboutData} />
        </div>
      </div>
      <div className="footer_fixed segoe-font-title">
        <div className="footer__bottom-content footer__bottom-content--reverse">
          <div className="fulfilled_by">
            ©{new Date().getFullYear()}, FULFILLED BY PARCELLE WINE
          </div>

          <div
            className="footer-contact"
            style={{ textTransform: "capitalize" }}>
            <a
              href="https://www.elevenmadisonhome.com/story/wine-club-frequently-asked-questions"
              target="_blank"
              rel="noreferrer">
              FAQs
            </a>{" "}
            | QUESTIONS?{" "}
            <a href="mailto:thewineclub@parcellewine.com">
              THEWINECLUB@PARCELLEWINE.COM
            </a>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
