import React from "react";

export default function Banner({ heroData }) {
  console.log({ heroData });

  return (
    <div className="banner-margin">
      <div className="css-dh0f3y">
        <div className="css-1fkg5dg">
          <div className="css-1b30rcz">
            <div className="css-1wll38a">{heroData?.mainTitle}</div>
            <div className="css-1wll38a">{heroData?.mainTitleLb}</div>
            {/* <a className="css-1b9w93t" tabindex="0" href={heroData?.link}>
              {heroData?.buttonText}
              <span className="css-w0pj6f"></span>
            </a> */}
          </div>
          <img
            src={heroData?.heroImage?.url}
            className="banner-image"
            alt={heroData?.heroImage?.alt}
          />
        </div>
      </div>
    </div>
  );
}
