import React from 'react';

export default function Content({ contentData }) {
  return (
    <div className='content-container'>
      <div className='content-texts'>
        <p className='content-info-text'>Have Questions? We're here to help</p>
        <p className='content-info-detail'>
          Get in touch at{' '}
          <a style={{ padding: 0, margin: 0, color: '#846032' }} href='mailto:thewineclub@parcellewine.com'>
            {' '}
            thewineclub@parcellewine.com{' '}
          </a>
        </p>
      </div>
      <div className='content-data-container'>
        <div className='content'>
          <p className='content-title'>{contentData?.header ? contentData.header : 'Content Section'}</p>
          <p className='content-title-detail'>{contentData?.paragraph}</p>
        </div>
        <div className='content-images'>
          {contentData?.images?.map((image, i) => (
            <img key={i} alt={image?.alt ? image.alt : 'Wine Glasses and Plants'} loading='lazy' className='content-img' effect='blur' src={image.url} />
          ))}
        </div>
      </div>
    </div>
  );
}
