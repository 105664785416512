import React from "react";
import StoryCard from "../Card/StoryCard";

export default function About({ aboutData }) {
  function NewlineText(paragraph) {
    const text = paragraph;
    const newText = text.split("\n").map((str) => (
      <>
        {str}
        <br />
      </>
    ));

    return newText;
  }

  return (
    <div className="container">
      <div
        className="hero-container "
        style={{ paddingTop: 10, marginTop: 10 }}>
        <div className="flex-container">
          <div className="flex-order-2 flex-item">
            <h1
              className="noe-font-title story-h1"
              style={{ fontFamily: "Nominee", fontWeight: 400 }}>
              {aboutData?.header ? aboutData?.header?.toUpperCase() : "ABOUT"}
            </h1>
          </div>
          <div className="flex-order-1 flex-item">
            {" "}
            <h3
              className="hero-h3"
              style={{
                color: "#846032",
                textAlign: "center",
                fontSize: "16px",
              }}>
              {aboutData?.paragraph ? (
                NewlineText(aboutData?.paragraph)
              ) : (
                <p>Eleven Madison</p>
              )}
            </h3>
          </div>
          <div className="flex-order-3 flex-item">
            <a
              className="read-more-btn"
              style={{ padding: "4px" }}
              tabindex="0"
              href={aboutData?.link}>
              Read More
              <span className="css-w0pj6f"></span>
            </a>
          </div>
        </div>
        {/* <div className='hero-text-container'></div> */}
      </div>
      {aboutData?.image1 && (
        <div className="storyCards-container">
          <StoryCard
            title={aboutData?.image1Title}
            description={aboutData?.image1Paragraph}
            imageUrl={aboutData?.image1?.url}
            alt={aboutData?.image1?.alt || "Wine Glasses and Plants"}
            readMoreLink={aboutData?.image1Link}
          />
          <StoryCard
            title={aboutData?.image2Title}
            description={aboutData?.image2Paragraph}
            imageUrl={aboutData?.image2?.url}
            alt={aboutData?.image2?.alt || "Wine Glasses and Plants"}
            readMoreLink={aboutData?.image2Link}
          />
          <StoryCard
            title={aboutData?.image3Title}
            description={aboutData?.image3Paragraph}
            imageUrl={aboutData?.image3?.url}
            alt={aboutData?.image3?.alt || "Wine Glasses and Plants"}
            readMoreLink={aboutData?.image3Link}
          />
          {/* <div className='flex-row-desktop'>
            {aboutData?.image1?.url && <img alt={aboutData?.image1?.alt || 'Wine Glasses and Plants'} loading='lazy' className='about-img' effect='blur' src={aboutData?.image1?.url} />}
            {aboutData?.image2?.url && <img alt={aboutData?.image2?.alt || 'Wine Glasses and Plants'} loading='lazy' className='about-img' effect='blur' src={aboutData?.image2?.url} />}
            {aboutData?.image3?.url && <img alt={aboutData?.image3?.alt || 'Wine Glasses and Plants'} loading='lazy' className='about-img' effect='blur' src={aboutData?.image3?.url} />}
            {aboutData?.image4?.url && <img alt={aboutData?.image4?.alt || 'Wine Glasses and Plants'} loading='lazy' className='about-img' effect='blur' src={aboutData?.image4?.url} />}
          </div> */}
        </div>
      )}

      <button className="read-more-btn-mobile">Read More</button>
    </div>
  );
}
